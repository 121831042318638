import React from "react"
import { FormattedMessage } from "react-intl"
import { Container, UncontrolledCarousel } from "reactstrap"
import { config } from "../../lib/config"

const pics = [
  "/web-catalog-app/assets/bestcareer/slider1.jpg",
  "/web-catalog-app/assets/bestcareer/slider2.jpg",
  "/web-catalog-app/assets/bestcareer/slider3.jpg",
  "/web-catalog-app/assets/bestcareer/slider4.jpg",
  "/web-catalog-app/assets/bestcareer/slider5.jpg",
  "/web-catalog-app/assets/bestcareer/slider6.jpg",
]

const BestCareerHero: React.FC = () => {
  return (
    <div className="hero">
      <div className="hero__image-container">
        <UncontrolledCarousel
          interval={8000}
          controls={false}
          indicators={false}
          pause={false}
          autoPlay={true}
          items={pics.map(src => ({
            src,
            key: src,
            altText: "Bestcareer",
            caption: "",
          }))}
        />
      </div>
      <Container className="mb-6 text-center">
        <div className="mb-2 mt-4 hero__info">
          <a href={config.urls.candidateApp} className="btn btn-primary btn-lg">
            <FormattedMessage
              id="bestcareer.hero.joinNow"
              defaultMessage="Join now"
            />
          </a>
        </div>
        <p className="font-italic">
          <FormattedMessage
            id="bestcareer.hero.poweredBy"
            defaultMessage="Powered by MoBerries"
          />
        </p>
      </Container>
    </div>
  )
}

export default BestCareerHero
